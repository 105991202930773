<template>
  <div>
    <Loading v-if="loading" />
    <Navbar v-if="!mobile && !loading" />
    <div class="flex pr-5" v-if="!loading">
      <div class="flex w-[17rem] order-1" v-if="!mobile"></div>
      <div
        class="flex w-full flex-col order-2 p-5"
        :class="{ 'px-16 py-12': !mobile }"
      >
        <Button
          icon="pi pi-align-left download_btn"
          @click="toggleVisibleLeft"
          v-if="mobile"
        />
        <Sidebar v-model:visible="visibleLeft" v-if="mobile">
          <div class="flex flex-col h-full justify-between">
            <ul class="md:flex-col md:min-w-full flex flex-col list-none">
              <li class="flex" v-for="(item, index) in NavItems" :key="index">
                <router-link
                  :to="{ name: item.path }"
                  class="mb-4 w-full img-pop rounded py-2 px-4"
                  :class="{ active: route === item.path }"
                >
                  <div class="flex items-center">
                    <i :class="item.icon" class="mr-2"></i>

                    {{ item.name }}
                  </div>
                </router-link>
              </li>
            </ul>

            <div class="btn flex justify-around items-center" @click="logout">
              Cerrar sesión
              <i class="pi pi-sign-out"></i>
            </div>
          </div>
        </Sidebar>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Navbar from "@/components/DashboardNav";
import EventBus from "@/common/EventBus";
export default {
  name: "dashboardLayout",
  components: {
    Navbar,
    Loading,
  },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      loading: null,
      visibleLeft: null,
      orgData: null,
      NavItems: [
        // {
        //   name: "Forecast",
        //   path: "home",
        //   icon: "pi pi-chart-bar",
        // },
        {
          name: "Administrador",
          path: "admin",
          icon: "pi pi-briefcase",
        },
        // {
        //   name: "tab-3",
        //   path: "bookBusiness",
        //   icon: "pi pi-money-bill",
        // },
        // {
        //   name: "tab-4",
        //   path: "wimms",
        //   icon: "pi pi-flag",
        // },
        // {
        //   name: "tab-5",
        //   path: "manageAgency",
        //   icon: "pi pi-briefcase",
        // },
        // {
        //   name: "tab-6",
        //   path: "uploadData",
        //   icon: "pi pi-folder",
        // },
      ],
    };
  },
  computed: {
    route() {
      return this.$route.name;
    },
  },

  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },

  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      this.mobile = this.windowWidth <= 750;
    },
    toggleVisibleLeft() {
      this.visibleLeft = !this.visibleLeft;
    },
    logout() {
      EventBus.dispatch("logout");
    },
  },
};
</script>
<style>
.active {
  background-color: rgba(88, 81, 81, 0.1);
}
</style>
