import { createStore } from "vuex";
import TokenService from "@/services/token.service";
import axios from "axios";
export default createStore({
  state: {
    email: null,
    password: null,
    complete: null,
    username: null,
  },
  getters: {},
  mutations: {},
  actions: {
    async forgot_password({ state, dispatch }, email) {
      let data = {
        params: {
          email: email,
        },
        endpoint: "forgot_password",
      };
      dispatch("postRequest", data);
      state.email = email;
    },
    async reset_password({ state, dispatch }, password) {
      let data = {
        params: {
          new_password: password,
        },
        endpoint: "reset_password",
      };
      let res = dispatch("postRequest", data);
      state.password = true;
      return res;
    },

    async postRequest({ state }, data) {
      console.log(data);
      const token = TokenService.getLocalAccessToken();
      let res = await axios.post(
        `https://equipetrol-api.datapaip.com/${data["endpoint"]}`,
        "",
        {
          params: data["params"],
          headers: {
            accept: "application/json",
            authorization: `Bearer ${token}`,
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      state.complete = true;
      return res.status;
    },
  },
  modules: {},
});
