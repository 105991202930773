<template>
  <div
    class="tabs-content h-full rounded shadow-lg p-5"
    v-show="title == selectedTitle.title"
  >
    <slot />
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  props: ["title"],
  setup() {
    const selectedTitle = inject("selectedTitle");

    return {
      selectedTitle,
    };
  },
};
</script>

<style lang="scss" scoped>
.tabs-content {
  background-color: white;
}
</style>
