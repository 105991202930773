<template>
  <div class="form-wrap">
    <form class="register rounded shadow-lg">
      <img src="@/assets/logo.png" class="w-1/2 mb-4" alt="" />
      <h4>Cambiar contraseña</h4>
      <h6 class="mb-4">
        Antes de continuar, por favor actualiza tu contraseña
      </h6>
      <div class="inputs">
        <div class="input">
          <input
            type="password"
            placeholder="Contraseña"
            class="rounded"
            v-model="password"
          />
          <i class="fa-solid fa-lock icon"></i>
        </div>
        <div class="input">
          <input
            type="password"
            placeholder="Confirmar Contraseña"
            class="rounded"
            @change="checkMatch()"
            v-model="confirmPassword"
          />
          <i class="fa-solid fa-lock icon"></i>
        </div>
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
        <span class="text-red-400 mt-2" v-if="newPasswordMismatch"
          >Asegurese que las contraseñas sean iguales</span
        >
      </div>
      <button @click.prevent="login" class="btn mt-6">Continuar</button>
      <br />
    </form>
  </div>
</template>

<script>
import TokenService from "@/services/token.service";

export default {
  name: "LoginView",
  data() {
    return {
      password: "",
      confirmPassword: "",
      newPasswordMismatch: null,
      error: null,
      errorMsg: "",
      mobile: false,
    };
  },
  computed: {
    temp() {
      return TokenService.getLocalTemp();
    },
  },
  mounted() {},
  methods: {
    checkMatch() {
      if (this.password !== this.confirmPassword) {
        this.newPasswordMismatch = true;
        return;
      }
      this.newPasswordMismatch = false;
    },
    async login() {
      if (!this.password) {
        this.error = true;
        this.errorMsg = "Please provide a password";
        return;
      }
      this.$store
        .dispatch("reset_password", this.password)
        .then((res) => {
          if (res == 200) {
            TokenService.updateLocalTemp(false);
            this.$router.push({ name: "home" });
          }
        })
        .catch((e) => {
          this.error = true;
          const errorMsg =
            e?.response?.data?.detail || "An unknown error occurred";
          this.errorMsg = errorMsg;
          console.log("Error logging in", e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  background-color: #031624;
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 100%;
  }
  .register {
    max-width: 550px;
    height: 750px;
    box-shadow: rgba(218, 217, 217, 0.16) 0px 10px 36px 0px,
      rgba(218, 217, 217, 0.16) 0px 0px 0px 1px;
    h2 {
      max-width: 350px;
    }
  }
  .login-register {
    margin-bottom: 32px;
    .router-link {
      color: #ff4d3d;
    }
  }
  form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media (min-width: 900px) {
      padding: 0 50px;
    }
    h4 {
      text-align: center;
      margin-bottom: 40px;
      @media (min-width: 900px) {
        font-size: 40px;
      }
    }
    .inputs {
      width: 100%;
      max-width: 350px;
      .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 4px 4px 4px 30px;
          height: 50px;
          &:focus {
            outline: none;
          }
        }
        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }
    .forgot-password {
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      margin: 48px 0 32px;
      border-bottom: 1px solid transparent;
      transition: 0.5s ease all;
      &:hover {
        border-color: #303030;
      }
    }
  }
  .background {
    flex: 2;
    background-image: url("@/assets/logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media (max-width: 900px) {
      display: none;
    }
    h1 {
      color: #f2f2f2;
    }
  }
}
.signInBtn {
  background-color: orange;
  transition: 0.5s ease all;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 400;
}
.error {
  color: #e2000b;
  text-align: center;
}
</style>
