import api from "./api";
class UserService {
  get(id) {
    return api.getData("/user/get", { params: { id: id } });
  }
  get_all() {
    return api.getData("/user/get_all");
  }
  delete(id) {
    return api.deleteData("/user/delete", { params: { id: id } });
  }
  create(email, password, role) {
    return api.postData("/user/create", {
      email: email,
      password: password,
      role: role,
    });
  }
}
export default new UserService();
