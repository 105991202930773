<template>
  <div class="modal">
    <div class="modal-content">
      <h6>¿Esta seguro?</h6>
      <small class="text-center mb-8">{{ modalMessage }}</small>
      <div class="flex w-full justify-center">
        <button
          class="btn cta mr-2"
          @click="runForecast"
          v-if="type == 'forecast'"
        >
          Confirmar
        </button>
        <button class="btn cta mr-2" @click="deleteUser" v-if="type == 'user'">
          Confirmar
        </button>
        <button class="btn ctaTwo mr-2" @click="closeModal">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalOption",
  props: ["modalMessage", "doc", "type"],
  data() {
    return {
      mobile: null,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 850) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    closeModal() {
      this.$emit("close-modal");
    },
    runForecast() {
      this.$emit("confirm");
    },
    deleteUser() {
      this.$emit("confirmDelete");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    width: 700px;
    background-color: #fff;
    @media (max-width: 850px) {
      padding: 20px 10px;
    }
    @media (min-width: 850px) {
      padding: 40px 30px;
    }

    p {
      text-align: center;
    }
    button {
      align-self: center;
    }
    @media (max-width: 850px) {
      width: 85vw;
    }
    .btn {
      cursor: pointer;
      transition: 0.5s ease all;

      &:hover {
        background-color: orange;

        transform: scale(1.05);
      }
      img {
        width: 10%;
      }
    }
  }
}
.signInBtn {
  background-color: orange;
  transition: 0.5s ease all;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
