class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.access_token;
  }
  getLocalRole() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.role;
  }
  getLocalTemp() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.temporary;
  }
  updateLocalTemp(temp) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.temporary = temp;
    localStorage.setItem("user", JSON.stringify(user));
  }
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.access_token = token;
    localStorage.setItem("user", JSON.stringify(user));
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  clearUser() {
    localStorage.removeItem("user");
  }

  trySaveToken = (tokenData) => {
    tokenData.access_token && this.setUser(tokenData);
    return tokenData;
  };
}
export default new TokenService();
